<template>
  <div class="">
    <div class="container mx-auto">
      <div class="lg:flex lg:justify-between mb-8">
        <div>
          <Intro msg="Eventi in programma" />
        </div>
        <div class="px-4">
          <div class="mb-4">
            <router-link
              to="/create-event"
              class="
                inline-block
                bg-red
                text-md text-white
                uppercase
                border border-red
                px-4
                py-1
                hover:text-red hover:bg-white
                cursor-pointer
                transition-colors
                duration-500
                ease-in-out
              "
              >Crea evento</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <Read :only_passed="false" :only_published="false" />
  </div>
</template>

<script>
import Intro from "@/components/component/Intro.vue";
import Read from "@/components/events/Read.vue";

export default {
  name: "ReadEvents",
  components: {
    Intro,
    Read,
  },
  methods: {},
};
</script>
